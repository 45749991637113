import axios, { AxiosRequestConfig } from "axios";
import { IConfigFileDownload } from "./types";
import { routerRef } from "../App";

export const baseURL = "https://api.impactpharm.app/";
// export const baseURL = "https://impactpharm.mor.com.vn/";
const apiClient = axios.create({
  baseURL,
  withCredentials: true,
  responseType: "json",
  timeout: 10000,
});

const apiClientDownload = axios.create({
  baseURL,
  withCredentials: true,
});

const defaultHeaders = {
  "Content-Type": "application/json",
};

const defaultConfig = () => {
  const defaultConfig: AxiosRequestConfig = {
    headers: { ...defaultHeaders },
  };
  return defaultConfig;
};

const mergeConfig = (config?: AxiosRequestConfig) => {
  const _defaultConfig = defaultConfig();
  let headers = _defaultConfig.headers;
  if (config && config.headers) {
    headers = { ...headers, ...config.headers };
  }
  return { ..._defaultConfig, ...config, headers };
};

const requestErrorHandler = (err?: any) => {
  if (err?.response?.data?.error_code === 8) {
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("full_name");
    localStorage.removeItem("jwtToken");
    routerRef.current.history.push("/auth/login");
    throw new Error(`Your token expired. Please login again.`);
  }
  return Promise.reject(err);
};

export async function get(url: string, config?: AxiosRequestConfig) {
  const _config = mergeConfig(config);
  return apiClient
    .get(`${url}`, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler);
}

export async function post(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  const _config = mergeConfig(config);
  return apiClient
    .post(`${url}`, data, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler);
}

export async function put(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  const _config = mergeConfig(config);
  return apiClient
    .put(`${url}`, data, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler);
}

export async function deleteRequest(url: string, config?: AxiosRequestConfig) {
  const data = config && config.data ? config.data : "";
  const _config = mergeConfig({ ...config, data });
  return apiClient
    .delete(`${url}`, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler);
}

export async function downloadFile(
  url: string,
  file_config: IConfigFileDownload,
  config?: AxiosRequestConfig
) {
  const _config = mergeConfig({
    ...config,
    responseType: "blob",
  });
  return apiClientDownload
    .get(`${url}`, _config)
    .then((res) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `${file_config.name_file}.${file_config.type_file}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => requestErrorHandler(err));
}
